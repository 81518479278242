/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

p,
a {
  font-family: "Lora", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

#hero {
  height: 50vh;
}

@media only screen and (min-width: 600px) {
  #hero {
    height: 80vh;
  }
}
